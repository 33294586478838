.log-list {
    padding-top: 0;
    margin-top: 0;
}

.log-list-item {
    background-color: #efefef;
    margin: 8px;
    padding: 2px;
}

.log-column {
    display: inline-block;
}

.w1 {
    width: 100px;
}

.w2 {
    width: 200px;
}

.w3 {
    width: 250px;
}

.th {
    background-color: #cdcdcd;
    margin: 8px 8px 0 8px;
    padding: 8px 4px;
    font-weight: bold;
    cursor: pointer;
}

.log-value-container {
    height: 100px;
    margin: 25px 1% 0 1%;
    background-color: #efefef;
    width: 98%;
    overflow-x: scroll;
    
}