.land-docs-container {
    overflow-y: scroll;
    height: 85vh;
    margin-top: 10px;
    margin-left: 10px;
}

.land-doc-description {
    width: 60%;
    background-color: lightgrey;
    padding: 1% 3%;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.land-doc-description:hover {
    background-color: #efefef
}

.land-doc-description-expanded {
    width: 60%;
    background-color: #efefef;
    padding: 1% 3%;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;
}

h2, h3 {
    text-align: left;
    background-color: inherit;
    color: black;
    padding: 0;
    margin-top: 3px;
    margin-bottom: 3px;
}

.land-sub-docs-container {
    color: blue;
}

.sub-item {
    display: inline-block;
    margin-left: 15px;
}

.pdf:hover {
    color:cornflowerblue;
}