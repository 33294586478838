.plot-list-container {
    height: 90vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
}

.plot-container {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

h2 {
    text-align: center;
}

.add-tenants-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-tenants-container {
    width: 70%;
    padding: 10px;
    display: flex;
    justify-content: space-around;
}

.left-column {
    width: 35%;
}

.filter-container {
    position: relative;
    margin-bottom: 1em;
}

.filter-text {
    position: absolute;
    right: 2px;
}

.search {
    position: absolute;
    right: 4px;
    top: 2px;
    z-index: 2;
}

.submit:hover {
    background-color: #333;
}

.add-link {
    border: solid 2px gray; 
    padding: 2px;
}

.add-link:hover {
    background-color: #efefef;
}

.plot {
    width: 15em;
    height: 15em;
    border: solid 2px black;
    padding: 0;
    overflow-y: scroll;
}

h3 {
    text-align: center;
    color: white;
    background-color: black;
    margin: 0;
    padding: 7px;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.two-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.save, .edit {
    padding: 6px;
    font-size: 18px;
    margin: 10px 0 10px 0;
    border: none;
    color: white;
    background-color: black;
    width: auto;
  }

  .edit {
    width: 8em;
  }

.save:hover, .edit:hover {
    background-color: grey;
}

.map-container {
    position: absolute;
    top: -40px;
    width: 100vw;
}
.map-container2 {
    position: absolute;
    top: 0px;
    width: 100vw;
}

.h-list {
    background-color: #ededed;
    color: #585858;
    font-size: 14px;
    padding: 5px;
    border: solid 1px #cfcfcf;
}

.plots-filter {
    position: relative;
    z-index: 3;
}

.inactive {
    pointer-events: none;
}
