.plot {
    fill: #3e7e00;
    fill-opacity: 0.7;
    stroke: #000000;
    stroke-width: 0.264583px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1
}

.path {
    fill: #ffd5d5;
    stroke: #ffd5d5;
    stroke-width: 0.264583px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1
}

.willow {
    fill: #9acd32;
    fill-opacity: 0.7;
    stroke: #000000;
    stroke-width: 0.264583px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1
}

.plot:hover, .willow:hover, .building:hover, .bay:hover {
    fill-opacity: 0.5;
}

.plot:hover, .highlighted:hover {
    stroke-width: 1px;
}

.building {
    stroke: #552200;
    stroke-width: 0.264583px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1;
    fill: gray;
}

.number, .label {
    font-size: 2.8px;
    line-height: 1.25;
    font-family: sans-serif;
    color: black;
    font-weight: 900;
    white-space: pre;
    display: inline;
    opacity: 1; 
    cursor: pointer;
}

.bay {
    display:inline;
    fill:none;
    stroke:#000000;
    stroke-width:0.264583px;
    stroke-linecap:butt;
    stroke-linejoin:miter;
    stroke-opacity:1;
    fill: rosybrown;
}

.other {
    display:inline;
    opacity:1;
    fill:none;
    stroke:#000000;
    stroke-width:0.26458300000000001px;
    stroke-linecap:butt;
    stroke-linejoin:miter;
    stroke-opacity:1;
}

.highlighted {
    fill: goldenrod;
    fill-opacity: 0.7;
    stroke: #000000;
    stroke-width: 0.264583px;
    stroke-linecap: butt;
    stroke-linejoin: miter;
    stroke-opacity: 1
}

.circle, .ellipse {
    opacity:1;
    fill: beige;
    stroke:#008000;
    stroke-width:0.264583;
}

text {
    color: #000;
}
