.contact-centre {
    width: 50%;
    margin-left: 25%;
}

.contact-form-container {
    width: 100%;
    /* display: flex;
    justify-content: center; */
}

.contact-subheading{ 
    font-size: 12pt;
}

.contact-form {
    width: 100%;
    margin-top: 30px;
}

.contact-group {
    width: 100%;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
}

.contact-label {
    display: inline-block;
    width: 22%;
    font-weight: bold;
    padding-right: 15px;
    vertical-align: top;
}

.contact-element {
    display: inline-block;
    width: 70%;
}

#contact-submit {
    display: flex;
    justify-content: flex-end;
}

.contact-submit {
    padding: 7px;
    font-size: 18px; 
    border: none;
    color: white;
    background-color: black;
    width: 4em;
}

.contact-submit:hover {
    background-color: #777;
}

.contact-error {
    color: red;
    font-weight: bold;
    width: 70%;
}

.contact-submitted {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-submitted h3 {
    color: black;
    margin-top: 70px;
}

.contact-submitted button {
    padding: 7px;
    font-size: 18px; 
    border: none;
    color: white;
    background-color: black;
    margin-top: 50px;
}

.contact-submitted button:hover {
    background-color: #777;
}

@media only screen and (max-width: 700px) {
    .contact-centre {
        width: 90%;
        margin-left: 5%;
    }

    .contact-group {
        width: 100%;
        margin-bottom: 12px;
        display: flex;
        flex-direction: column;
    }
    .contact-label {
        width: 80%;
        margin-bottom: 3px;
    }
    .contact-element {
        width: 100%;
    }
}