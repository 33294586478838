.container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 30px;
}

/* .container div{
    width: 50%;
} */

.scrollable {
    height: 90vh;
    overflow-y: scroll;
}


ul {
    font-size: 20px;
    width: 100%;
    height: 90vh;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 0;
    margin-top: 0; 
    padding-top: 10px;
    padding-left: 0;
    padding-right: 3px;
}

a {
    text-decoration: none;
}

a, a:visited {
    color: inherit;
}

li {
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    background-color: #efefef;
}

.list-item {
    width: 94%;
    display: flex;
    
    margin: 2px;
    margin-right: 0;
    border: solid 2px gray; 
    color: #444;
    padding: 4px;
    transition: 200ms;
}
    
.list-item:hover {
    color: #000;
    background-color: #efefef;
}

.list-item:hover .del {
    display: contents;
}

.list-item-link  {
    display: block;
    width: 90%;
}

.del {
    width: 1em;
    height: 100%;
    color: grey;
    display: none;
    font: 12pt bold;
}

.del:hover {
    color: #d44;
    font-size: 13pt;
    cursor: pointer;
}

.plots-container {
    position: relative;
    margin: 0;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.view-select {
    z-index: 2;
}

.view-select button {
    border: solid 2px black;
}

.plot-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.plot-list-item {
    width: 7em;
    height: 7em;
    background-color: #efefef;
    border: 2px solid black;
    margin: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.plot-list-item:hover { 
    background-color: lightgrey;
}

.plot-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gold {
    background-color: gold;
}