.form-container {
    /* height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center; */
    width: 38%;
  }

  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    background-color: #dcdcdc;
    padding: 8px;
  }

  .form-header {
    background-color: black;
    color: white;
    padding: 10px;
    font-size:18px;
    font-weight: bold;
  }

  .form-group {
    display: inherit;
    margin-top: 7px;
    color: #585858;
  }

  .form-group label {
    color: black;
    display: inline-block;
    width: 38%;
    text-align: left;
    vertical-align: middle;
    padding-left: 5px;
  }

  .form-group input, .form-group textarea, .form-group select {
    padding: 5px;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 2px;
    width: 50%;
    display: inline-block;
  }

  .cb-container {
    padding: 5px;
    padding-left: 0;
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 0;
    /* width: 58%; */
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
  }

  input[type=checkbox]{
    width: 20px;
    margin-left: 0;
  }

  .submit {
    padding: 10px;
    font-size: 18px;
    margin: 10px 2px; 
    border: none;
    color: white;
    background-color: black;
    width: 4em;
  }

  .cancel {
    padding: 10px;
    font-size: 18px;
    margin: 10px 2px; 
    border: none;
    color: white;
    background-color: red;
    width: 4em;
  }

  .cancel:hover {
    background-color: #333;
}

  .message {
    color: red;
    font-weight: bold;
  }

  .right-flex {
    display: flex;
    justify-content: flex-end;
  }
 
 