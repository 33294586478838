body {
  font-family: corbel;
  background-color: #D0F0C0;
}

h1 {
  text-align: center;
  font-size: 30px;
}

.vertical-flex {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.loading-container {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}
figcaption {
  width: 60%;
}

.about-img {
  width: 60%;
}

p {
  color: #333
}

@media only screen and (max-width: 700px) {

  p {
    font-size: 22px;
  }

  section {
    margin: 2%;
  }
  figure {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    text-align: center;
    margin: 0
  }
  figcaption {
    width: 100%;
  }
  .about-img {
    width: 100%;
    float: left;
  }
}
