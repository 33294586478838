.register {
    height: 80vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .register__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background-color: #dcdcdc;
    padding: 30px;
  }
  .register__textBox {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .register__btn {
    padding: 10px;
    font-size: 18px;
    margin-bottom: 10px;
    border: none;
    color: white;
    background-color: black;
  }
  .register__btn:hover {
    background-color: #333;
  }
 
  .register div {
    margin-top: 7px;
  }